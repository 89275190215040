import { AdvicePageImageEntity } from '@cjm/v-loket/shared';

import { AfbeeldingResultEntity } from '../../services';

export const pageImageParser = (image: AfbeeldingResultEntity): AdvicePageImageEntity => {
	return {
		url: image.locatieUrl,
		alt: image.altTekst,
		height: image.hoogte,
		width: image.breedte,
		type: image.type
	};
};
